'use client';
import { LoadingOverlay, Paper } from '@mantine/core';

export const Loader = ({ isLoading }: { isLoading: boolean }) => (
  <Paper shadow="md" mt={10} p={25} radius="md" withBorder>
    <LoadingOverlay
      loaderProps={{ size: 'sm', color: 'pink', variant: 'bars' }}
      overlayOpacity={0.5}
      overlayColor="#c5c5c5"
      visible={isLoading}
    />
  </Paper>
);
